<template>
  <v-card flat class="segmented">
    <v-card-title v-if="value.title">
      <span v-html="localize(value.title)"></span>

      <template v-if="localize(value.tooltip)">
        <v-tooltip left max-width="500">
          <template v-slot:activator="{ on: tooltip }">
            <v-icon v-on="{ ...tooltip }" class="ml-1">mdi-information</v-icon>
          </template>
          <span v-html="localize(value.tooltip)"></span>
        </v-tooltip>
      </template>
    </v-card-title>

    <v-card-text>
      <p
        v-if="!value.subtitleAsTooltip && localize(value.subtitle) !== ''"
        v-html="localize(value.subtitle)"
      ></p>

      <v-alert v-if="dataTransferred" type="warning" text>
        {{
          $t('feedbacks.dataTransferred', {
            type: $t(`feedbacks.processTypes.${dataTransferProcessType}`)
          })
        }}
      </v-alert>

      <div
        v-if="value.useScale && !feedbackData.canCompareOnly"
        class="scale-wrapper mb-2 d-block"
      >
        <!-- <div
          v-for="val in availableValues"
          :key="`el_${value.id}_val_${val}`"
          class="scale-value-item text-center"
        >
          <p>{{ getAnchor(val) }}</p>
          <v-icon>{{ getIconClass(val) }}</v-icon>
        </div> -->
        <item-scale
          v-model="value.value"
          :availableValues="availableValues"
          :anchors="value.anchors"
          :showAnchors="true"
          @change="saveValue"
        ></item-scale>
      </div>

      <div>
        <v-sheet
          v-if="feedbackData && feedbackData.isComparison"
          color="white"
          class="pa-2 mb-2"
        >
          <template v-for="item in feedbackData.comparisonData">
            <div
              :key="`comment_item_${value.id}_fb_${item.feedbackId}`"
              class="item-comment"
            >
              <strong>
                {{ $t(`feedbacks.typeOptions.${item.feedbackType}`) }}:
              </strong>

              <span
                v-html="getComparisonComment(value.id, item.feedbackType)"
              ></span>
            </div>
          </template>
        </v-sheet>

        <comment-field
          v-model="itemComment"
          v-if="value.allowComment && !feedbackData.canCompareOnly"
          :type="value.commentFieldType"
          :readOnly="readOnly"
          :printView="printView"
          :value-state="value.commentState"
          @change="saveComment"
        ></comment-field>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import elementMixin from '@/mixins/form-element.js';
import validationMixin from '@/mixins/form-validation.js';
import itemScale from './item-scale.vue';
import { mapActions, mapState } from 'vuex';
import { errorCodes } from '@/enums/errorCodes.js';
import { feedbackEnums } from '@/enums/feedbacks.js';

import commentField from './commentField/comment-field.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    },

    transferredData: {
      type: Object,
      required: false,
      default: () => null
    }
  },

  mixins: [elementMixin, validationMixin],

  data: () => ({
    availableValues: [],
    itemComment: '',
    itemValue: -1,
    dataTransferComplete: false,
    dataTransferred: false,
    dataTransferProcessType: -1,
    isInitialized: false
  }),

  computed: {
    ...mapState('feedbacks', {
      feedbackData: 'currentFeedbackData'
    })
  },

  watch: {
    itemComment(value, previousValue) {
      this.value.comment = value;


      if (
        this.isInitialized &&
        value !== previousValue &&
        this.value.commentState === feedbackEnums.valueState.COPIED
      ) {
        this.value.commentState = feedbackEnums.valueState.COPIED_AND_UPDATED;
      }

      this.isInitialized = true;
    },

    itemValue() {
      this.value.value = this.itemValue;
    },

    value() {
      this.$input('input', this.value);
    },

    'value.comment'() {
      this.itemComment = this.value.comment;
    },

    transferredData() {
      if (this.transferredData !== null) {
        this.setTransferredValue(this.transferredData);
      }
    }
  },

  mounted() {
    const values = [];
    for (let i = this.value.minValue; i <= this.value.maxValue; i++) {
      values.push(i);
    }

    this.itemComment = this.value.comment;
    this.itemValue = this.value.value;

    this.availableValues = values;
  },

  methods: {
    ...mapActions({
      saveItemComment: 'feedbacks/saveComment',
      saveItemValue: 'feedbacks/saveItemValue'
    }),

    getAnchor(val) {
      if (
        this.value.anchors === null ||
        this.value.anchors === undefined ||
        this.value.anchors.length === 0
      ) {
        return '';
      }

      const anchor = this.value.anchors.filter((x) => x.value === val);
      if (anchor.length === 0) {
        return '';
      }

      return this.localize(anchor[0].translation);
    },

    getComparisonComment(itemId, feedbackType) {
      if (this.feedbackData === null) {
        return '';
      }

      var item = this.feedbackData.comparisonData.filter(
        (x) => x.feedbackType === feedbackType
      );
      if (item.length === 0) {
        return `<i class="muted">${this.$t('feedbacks.noComment')}</i>`;
      }

      var val = item[0].comments.filter(
        (x) => x.elementId === this.value.id && x.matrixItemId === null
      );

      if (val.length === 0) {
        return `<i class="muted">${this.$t('feedbacks.noComment')}</i>`;
      }

      return val[0].comment;
    },

    async saveComment() {
      const result = await this.saveItemComment({
        feedbackId: this.$route.params.id,
        elementId: this.value.id,
        itemId: null,
        comment: this.itemComment,
        accessToken: this.$route.params.accessToken
      });

      if (
        result.errorCode &&
        result.errorCode === errorCodes.errorCodes.UNEXPECTED_EXCEPTION
      ) {
        this.$emit('errorOnSave');
      }
    },

    async saveValue() {
      const result = await this.saveItemValue({
        feedbackId: this.$route.params.id,
        elementId: this.value.id,
        value: this.value.value,
        accessToken: this.$route.params.accessToken
      });

      if (
        result.errorCode &&
        result.errorCode === errorCodes.errorCodes.UNEXPECTED_EXCEPTION
      ) {
        this.$emit('errorOnSave');
      }
    },

    setTransferredValue(data) {
      if (!this.value.transferFromElement) {
        return;
      }

      if (this.itemComment && !this.itemComment !== '') {
        return;
      }

      if (data.comment === '') {
        return;
      }

      this.dataTransferred = true;
      this.itemComment = data.comment;
      this.dataTransferProcessType = data.processType;
      this.dataTransferComplete = true;

      this.saveComment();
    },

    validate() {
      const result = this.validateQuestionElement(this.value);
      return result;
    }
  },

  components: {
    commentField,
    itemScale
  }
};
</script>

<style lang="scss" scoped>
.scale-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin-top: 2em;
}

.item-comment {
  white-space: pre-line;
}

.v-card.segmented .v-card__title {
  word-break: normal;
  font-size: 0.875rem !important;
  letter-spacing: 1px;
}
</style>
